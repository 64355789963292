<template>

	<GlobSpinner :isLoading="isLoading" />
	<div class="from-to-date-select">
		<label class="mas-mb-2">
			{{$t('reports.weekly.from_to_date')}}*
		</label>
		<div>
			<VueDatePicker 
			v-model="dateRange" 
			range 
			multi-calendars 
			:enable-time-picker="false" 
			:format="formatDate"
			@update:model-value="dataRangeSelected"/>
		</div>
	</div>

	<GlobRow>
		<GlobCol :medium="7" class="mas-mt-4">
			<ChartView 
			:data="chartDataModel"
			:sensorData="sensorData"
			:isLoading="isLoadingStartStopChart"
			@updateChart="updateChart"
			></ChartView>
		</GlobCol>
		<GlobCol :medium="5" class="mas-mt-4">
			<GlobRow>
				<GlobCol :medium="12" class="mas-mt-4">
					<DoneMaintenance
						:data="doneMaintenanceBody"
						:isLoading="isLoadingDoneMaintenanceTable"
						@update="getReportData('done_maintenance', $event)"
					></DoneMaintenance>
				</GlobCol>	
				<GlobCol :medium="12">
					<GlobXpanel :title="$t('reports.weekly.asset_stops')">
						<GlobSpinner :isLoading="isLoadingAssetStopsTable" />
						<div class="asset-stop-show-approved-selection mas-mt-2 mas-mb-2">
							<label class="mas-mt-2 mas-mb-2">
								{{ $t('reports.weekly.show_approved') }}
								<InputCheckbox
									class="mas-ml-2"
									v-model="showApproved"
									/>
							</label>
						</div>		
						<GlobTable
							:maxHeight="30"
							:data="{
								head : assetStopsHead,
								body : assetStopsBody
							}"
							:itemRenderFunctions="assetStopsRenderFunctions"
							:actions="assetStopeActions"
						/>
					</GlobXpanel>
				</GlobCol>
			</GlobRow>
		</GlobCol>
	</GlobRow>


	<!-- Show approve  oee service modal -->
	<GlobModal :widthInPx="800" 
				:title="selectedOeeLog && selectedOeeLog.IsApproved ? $t('maintenance_plan.oee.view_report') : $t('maintenance_plan.oee.approve_report')" @close="showOeeApproveServiceModal = false" 
			:show="showOeeApproveServiceModal && !hideApproveOeeServiceModal" 
			v-if="showOeeApproveServiceModal">
			<OeeApproveService 
				:oeeTimeLogId="selectedOeeLog.Id"
				@close="oeeServiceApproved()"
				@hideApproveModal="(val) => hideApproveOeeServiceModal = val"
				>
			</OeeApproveService>
		</GlobModal>

  </template>
  
  <script setup>
  import { ref, computed, watch } from "vue";
  import { onMounted } from "vue";
  import { useApi } from "@/plugins/api";
  import { useI18n } from 'vue-i18n';
  import { minutesToHumanReadableTime } from '@/helpers.js';
  import moment from 'moment';

  import OeeApproveService from '@/views/Maintenance/OEE/ApproveService.vue';
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';

  import ChartView from './Chart.vue';
  import DoneMaintenance from './DoneMaintenance.vue';

  //Date range should set to today and 7 days ago
  const dateRange = ref([new Date(new Date().setDate(new Date().getDate() - 7)), new Date()]);

  const { tm } = useI18n();

  const isLoading = ref(false);
  const api = useApi();
  const endpoint = api.reports;
  const assetStopsBody = ref([]);
  const chartDataModel = ref([]);
  const sensorData = ref([]);
  const showApproved = ref(false);

  const isLoadingDoneMaintenanceTable = ref(false);

  const selectedOeeLog = ref(null);
  const showOeeApproveServiceModal = ref(false);
  const isLoadingAssetStopsTable = ref(false);
  const hideApproveOeeServiceModal = ref(false);
  const isLoadingStartStopChart = ref(false);
  const doneMaintenanceBody = ref([]); 


	const assetStopsHead = ref([
		{
			Alias: "AssetName",
			Value: computed(() => tm('reports')['weekly']['machine'])
		},
		{
			Alias: "ReasonName",
			Value: computed(() => tm('reports')['weekly']['reason'])
		},
		{
			Alias: "ReportedBy",
			Value: computed(() => tm('reports')['weekly']['reported_by'])
		},
		{
			Alias: "StopDatetime",
			Value: computed(() => tm('reports')['weekly']['stopped_at'])
		},
	])

  const assetStopsRenderFunctions = computed(()=> {
		return {
			ReasonName(obj){
				return obj.ReasonName ? obj.ReasonName : obj.Description;
			},
		}
	});

	const assetStopeActions = computed(()=> { return [
		{
			icon: 'fas fa-file-alt',
			description: tm('maintenance_plan')['oee']['view_report'],
			if: (obj) => {
				return obj.ReportedByAccountId && obj.IsApproved ? true : false;
			},
			color: "#01188d",
				action: (obj) => {
					selectedOeeLog.value = obj;
					showOeeApproveServiceModal.value = true;
				}
		},
		{
			icon: 'far fa-eye',
			if: (obj) => {
				return !obj.IsApproved && obj.ReportedByAccountId  ? true : false;
			},
					description: tm('maintenance_plan')['oee']['approve'],
					action: (obj) => {
						selectedOeeLog.value = obj;
						showOeeApproveServiceModal.value = true;
					}
		},
	]});

	const oeeServiceApproved = () => {
		getAssteStopData();
		showOeeApproveServiceModal.value = false
	}
  
  onMounted(() => {
		getReportData();
  });

  const getReportData = async (type = null, settings = null) => {

	if(type == 'done_maintenance'){
		isLoadingDoneMaintenanceTable.value = true;
	} else if(type == 'asset_stops'){
		isLoadingAssetStopsTable.value = true;
	}else if(type == 'chart'){
		isLoadingStartStopChart.value = true;
	}else{
		isLoading.value = true;
	}

	endpoint.getWeeklyReport({
		from: dateRange.value[0] ? moment(dateRange.value[0]).format('YYYY-MM-DD') : null,
		to: dateRange.value[1] ? moment(dateRange.value[1]).format('YYYY-MM-DD') : null,
		type: type,
		settings: settings
	}).then((res) => {
		if(!type){
			doneMaintenanceBody.value = res.done_maintenance;

			assetStopsBody.value = res.asset_stops;

			chartDataModel.value = res.start_stop_chart.chartData;
			sensorData.value = res.start_stop_chart.sensorData;
		}else if(type == 'done_maintenance'){
			doneMaintenanceBody.value = res.done_maintenance;
			isLoadingDoneMaintenanceTable.value = false;
		} else if(type == 'asset_stops'){
			assetStopsBody.value = res.asset_stops;
			isLoadingAssetStopsTable.value = false;
		}else if(type == 'chart'){
			chartDataModel.value = res.start_stop_chart.chartData;
			sensorData.value = res.start_stop_chart.sensorData;
			isLoadingStartStopChart.value = false;
		}
		
		isLoading.value = false;
	});
};

  	const formatDate = (date) => {
		if (!date) return '';
		if (Array.isArray(date)) {
			// Format each date in a range
			return date.map(d => new Date(d).toISOString().split('T')[0]).join(' - ');
		}
		return new Date(date).toISOString().split('T')[0]; // Format a single date
	};

	const dataRangeSelected = (date) => {
		if(date){
			getReportData();
		}
	};

	const updateChart = (event) => {
		getReportData('chart', event);
	};

	watch(() => showApproved.value, () => {
		getAssteStopData();
	})

	const getAssteStopData = () => {
		getReportData('asset_stops', {
			showApproved: showApproved.value
		});
	}
  </script>
  
  <style lang="scss" scoped>

  .from-to-date-select{
	width: 250px;
  }

  .asset-stop-show-approved-selection{
	display: flex;
	justify-content: end;

	label{
		display: flex;
		align-items: center;
	}
  }
  </style>
  
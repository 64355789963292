<template>
    <draggable
      v-model="items"
      :group="dragGroup"
      :handle="dragHandle"
      :animation="animationSpeed"
      :swap-threshold="swapThreshold"
    >
      <template #item="{ element: treeViewItem }">
        <div class="tree-item">
          <!-- Drag Handle -->
          <div
            class="drag-handle"
            :style="{ cursor: 'grab' }"
            :data-item-id="treeViewItem.id"
          >
            <!-- Tree View for the Current Item -->
            <vue3TreeVue
                class="basic-tree-view"
              :items="[treeViewItem]"
              :disableDragAndDrop="true"
              @onSelect="onTreeItemSelected"
              @dropValidator="onBeforeItemDropped"
              v-if="treeViewItem"
            >
              <template v-slot:item-append="treeViewItem">
                <template v-if="treeViewItem">
                    <div class="tree-view-append-data" v-if="!treeViewItem.children">
                    <p v-for="label in labels" :key="label.name">
                        <b>{{ label.name }}:</b>
                        {{ treeViewItem[label.value] }}
                    </p>
                    </div>
                </template>
              </template>
  
              <template v-slot:item-prepend-icon="treeViewItem" >
                <div class="tree-icons"
  
                    >
                  <div
                    class="guide-line"
                    v-if="treeViewItem.Level == 0 && (!treeViewItem.children || !treeViewItem.children.length)"
                  ></div>
                  <template v-if="treeViewItem.children">
                    <img
                      src="@/assets/icon/machine-icon.png"
                      :alt="treeViewItem.name"
                      height="15"
                      width="15"
                    />
                  </template>
                  <template v-else>
                    <img
                      src="@/assets/icon/engine-icon.png"
                      :alt="treeViewItem.name"
                      height="15"
                      width="15"
                    />
                  </template>
                </div>
              </template>
            </vue3TreeVue>
          </div>
        </div>
      </template>
    </draggable>
  </template>
  
  <script setup>
  import { ref, defineProps, watch, onMounted } from 'vue';
  import vue3TreeVue from 'vue3-tree-vue';
  import draggable from 'vuedraggable';
  
  // Props for customization
  const props = defineProps({
    initialItems: {
      type: Array,
      required: true,
      default: () => [],
    },
    dragGroup: {
      type: String,
      default: 'tree',
    },
    dragHandle: {
      type: String,
      default: '.drag-handle',
    },
    animationSpeed: {
      type: Number,
      default: 200,
    },
    swapThreshold: {
      type: Number,
      default: 0.65,
    },
    labels: {
      type: Array,
      default: () => [
        { name: 'Manufacture', value: 'Manufacture' },
        { name: 'Production Year', value: 'ProductionYear' },
        { name: 'Installation Year', value: 'InstallationYear' },
      ],
    },
  });

  const emits = defineEmits(['selected', 'sorted']);
  
  const items = ref(props.initialItems);

  onMounted(() => {
    console.log('Tree View Items:', items.value);
  });

  watch(
    () => props.initialItems,
    (newVal) => {
      items.value = [...newVal];
    }
  );
  
  const onBeforeItemDropped = (draggedItem, dropItem) => {

	const supportedLevels = [0, 1];

    return new Promise((resolve) => {
      if (draggedItem && dropItem && supportedLevels.includes(draggedItem.Level) && supportedLevels.includes(dropItem.Level)) {

        if (draggedItem.isParent && dropItem.isParent) {
			const draggedIndex = items.value.findIndex((item) => item.id === draggedItem.id);
			const dropIndex = items.value.findIndex((item) => item.id === dropItem.id);

			// Remove the dragged item from its original position
			const [draggedItemRemoved] = items.value.splice(draggedIndex, 1);

			// Insert the dragged item at the drop index
			items.value.splice(dropIndex, 0, draggedItemRemoved);

           // Emit the sorted items
           emits('sorted', {
            'level': 'parent',
            'items': items.value.map((item) => item.id),
           });

        } else if (!draggedItem.isParent && !dropItem.isParent) {
          	if (draggedItem.parentId === dropItem.parentId) {
						const parentIndex = items.value.findIndex(
				(item) => item.id === dropItem.parentId
			);

			const children = items.value[parentIndex].children;

			// Find indices of the dragged and dropped child items
			const draggedChildIndex = children.findIndex(
				(child) => child.id === draggedItem.id
			);
			const dropChildIndex = children.findIndex(
				(child) => child.id === dropItem.id
			);

			// Remove the dragged item from its original position
			const [draggedChild] = children.splice(draggedChildIndex, 1);

			// Insert the dragged item at the drop index
			children.splice(dropChildIndex, 0, draggedChild);

			// Update the children array in the parent
			items.value[parentIndex].children = children;


            // Emit the sorted items
            emits('sorted', {
              'level': 'child',
              'parentId': dropItem.parentId,
              'items': children.map((child) => child.id),
            });
          }
        }
      }
      resolve(false);
    });
  };

  const onTreeItemSelected = (item) => {
        emits('selected', item);
    };
  </script>
  
  <style lang="scss">
  .basic-tree-view {
  .tree-item-node-parent {
    list-style: none;
    border-left: 1px dashed rgb(192, 192, 192);

    .tree-item__checkbox-area {
      display: flex;
      column-gap: 4px;
    }
  }

  .tree-item-node {
    position: relative;
  }

  .tree-item-node-parent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    row-gap: 12px;
  }

  .tree-view-append-data {
    display: flex;
    -moz-column-gap: 29px;
    column-gap: 29px;
    position: absolute;
    left: 18rem;
    top: 7px;
    width: max-content;

    p {
      margin: 0;
      font-size: 17px;
      min-width: 19rem;
      max-width: 19rem;

      b {
        margin-right: 2px;
      }
    }
  }

  .tree-item-node {
    font-size: 17px;
  }

  .tree-item {
    span {
      width: 16rem;
    }
  }

  .tree-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4px;

    .guide-line {
      margin-right: 6px;
    }
  }

  .tree-item-node {
    margin-left: -7px;
  }

  .chevron-right {
    background-color: white;
    margin-left: -4px;
  }

  .node-child {
    .tree-item-node {
      margin-left: unset !important;
    }
  }
}
  </style>
  
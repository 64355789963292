<template>
    <GlobXpanel :title="$t('reports.weekly.done_maintenance')">
        <GlobSpinner :isLoading="isLoadingDoneMaintenanceTable" />
        <div class="top-actions mas-mt-2">
            <button class="button button--margin button--small button--orange mas-mb-4"
            @click="showSinglePlaningModal = true"
            >{{$t('reports.weekly.single_planing')}}</button>
            <label>
                {{ $t('reports.weekly.show_approved') }}
                <InputCheckbox
                    class="mas-ml-2"
                    v-model="showApproved"
                    />
            </label>
        </div>
        <GlobTable
            :maxHeight="30"
            :data="{
                head : doneMaintenanceHead,
                body : doneMaintenanceBody
            }"
            :actions="doneMaintenanceTableActions"
        />
    </GlobXpanel>

    <!-- Show service logs -->
	<ViewReport 
		:serviceLogId="selectedServiceLog.Id" 
		@close="showServiceLogsModal = false, selectedServiceLog = null"
		v-if="showServiceLogsModal">
	</ViewReport>

	<!-- Show approve service modal -->
	<GlobModal :widthInPx="800" 
			:title="selectedServiceLog && selectedServiceLog.ApprovalStatus == 'approved' ? $t('maintenance_plan.service_log_menu.view.heading') : $t('maintenance_plan.service_log_menu.approve_service.heading')" @close="showApproveServiceModal = false" 
		:show="showApproveServiceModal && !hideApproveServiceModal" v-if="showApproveServiceModal">
		<ApproveService 
			:serviceLogId="selectedServiceLog.Id"
			@close="serviceApproved()"
			@hideApproveServiceModal="(val) => hideApproveServiceModal = val"></ApproveService>
	</GlobModal>

    <!-- Plan single planing -->
    <GlobModal :widthInPx="800" 
            :title="$t('reports.weekly.single_planing')" @close="showSinglePlaningModal = false" 
			:show="showSinglePlaningModal" v-if="showSinglePlaningModal">
			<CreateSinglePlan 
			@singlPlanCreated="showSinglePlaningModal = false"
			/>
		</GlobModal>
</template>


<script setup>
import { ref, computed, watch } from "vue";
import { useI18n } from 'vue-i18n';

import ViewReport from '@/views/Maintenance/ServiceLog/ViewReport.vue';
import ApproveService from '@/views/Maintenance/ServiceLog/ApproveService.vue';
import CreateSinglePlan from '@/views/Maintenance/Scheduled/CreateSinglePlan.vue';


const isLoadingDoneMaintenanceTable = ref(false);
const { tm } = useI18n();

const props = defineProps({
    data: {
        type: Object,
        required: true
    },
    isLoading: {
        type: Boolean,
        required: false,
        default: false
    }
});

const emits = defineEmits(['update']);

const doneMaintenanceHead = ref([
    {
        Alias: "AssetName",
        Value: computed(() => tm('reports')['weekly']['machine'])
    },
    {
        Alias: "Comment",
        Value: computed(() => tm('reports')['weekly']['comment'])
    },
    {
        Alias: "DoneAt",
        Value: computed(() => tm('reports')['weekly']['done_time'])
    },
]);

const doneMaintenanceBody = ref([]);
const selectedServiceLog = ref(null);
const showApproveServiceModal = ref(false);
const hideApproveServiceModal = ref(false);
const showServiceLogsModal = ref(false);
const showApproved = ref(false);
const showSinglePlaningModal = ref(false);

watch(() => props.data, (newVal) => {
    doneMaintenanceBody.value = newVal;
});

watch(() => props.isLoading, (newVal) => {
    isLoadingDoneMaintenanceTable.value = newVal;
});

watch(() => showApproved.value, () => {
    refreshData()
})

const refreshData = () => {
     emits('update', {
        showApproved: showApproved.value
     });
}


const doneMaintenanceTableActions = computed(() => { return [
		{
			icon: 'fas fa-file-alt',
			if: (obj) => {
				return obj.ApprovalStatus == 'approved' ? true : false;
			},
			description: tm('maintenance_plan')['service_logs']['service_logs'],
			color: "#01188d",
				action: (obj) => {
					selectedServiceLog.value = obj;
                    showApproveServiceModal.value = true;
				}
		},
		{
			icon: 'far fa-eye',
			if: (obj) => {
				return obj.ApprovalStatus != 'approved' ? true : false;
			},
			description: tm('maintenance_plan')['service_log_menu']['approve'],
			action: (obj) => {
				selectedServiceLog.value = obj;
				showApproveServiceModal.value = true;
				
			}
		},
		/**{
			icon: 'fas fa-file-alt',
			if: (obj) => {
				return obj.NeedValidation && obj.ApprovalStatus == 'approved' ? true : false;
			},
			color: "#01188d",
			description: tm('maintenance_plan')['service_log_menu']['approve'],
			action: (obj) => {
				selectedServiceLog.value = obj;
				showApproveServiceModal.value = true;
			}
		},
            **/
		];
	});

    const serviceApproved = () => {
        refreshData()
		showApproveServiceModal.value = false
	}
</script>

<style lang="scss" scoped>

.top-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    label{
        display: flex;
        align-items: center;
    }
}
</style>

import { ref } from "vue";

const highestZIndex = ref(1000); // Starting z-index value

export const useZIndexManager = () => {
  const getNextZIndex = () => {
    highestZIndex.value += 1;
    return highestZIndex.value;
  };
  return { getNextZIndex };
};

<template>
    <div class="mas-glob-qr-code">
        <GlobSpinner :isLoading="isLoading" />
        <div @click="enlargeQrCode(true)" class="qr-code-section">
            <VueQRCodeComponent :text="content" :size="size" ref="qrCodeRef"/>
            <p class="enlarge" v-if="enableEnlarge">{{$t('general.qr_code.click_to_enlarge')}}</p>
        </div>
        <div class="download-options" v-if="enableDownload">
            <span @click="downloadQRCode('pdf')"><i class="far fa-file-pdf"></i> {{$t('general.qr_code.download_as_pdf')}}</span>
            <span @click="downloadQRCode('image')"><i class="fas fa-file-image"></i>{{$t('general.qr_code.download_as_image')}}</span>
        </div>
    </div>
    <Transition>
    <GlobModal 
        :classes="'mas-glob-qr-code-modal'"
        :widthInPx="500"  @close="enlargeQrCode(false)" :show="showEnlargedQrCode" :isCRUD="true" v-if="showEnlargedQrCode">
        <VueQRCodeComponent :text="content" size="500" ref="qrCodeRef"/>
        <button class="button button--small button--orange mas-mt-3" @click="enlargeQrCode(false)">{{$t('general.button.close')}}</button>
    </GlobModal>
    </Transition>
</template>

<script>
import { ref, onMounted } from 'vue';
import VueQRCodeComponent from 'vue-qrcode-component';
import jspdf from 'jspdf';

export default {
	props: {
        name: {
            type: String,
			required: true
        },
        content: {
			type: String,
			required: true
		},
        size: {
            type: Number,
			default: 120
        },
        enableEnlarge: {
            type: Boolean,
			default: true
        },
        enableDownload: {
            type: Boolean,
			default: true
        },
        downloadFileName: {
            type: String,
			default: 'Qrcode'
        }
    },
    components: {
        VueQRCodeComponent
    },
    setup(props, { emit }){
        const isLoading = ref(false);
        const qrCodeRef = ref(null);
        const showEnlargedQrCode = ref(false);

        const enlargeQrCode = (show) => {
            if(props.enableEnlarge){
                if(show){
                    emit('enlargeQRCodeActive', true);
                    showEnlargedQrCode.value = true;

                }else{
                    showEnlargedQrCode.value = false;
                    emit('enlargeQRCodeActive', false);
                }
                
            }
        }

        const downloadQRCode = async(type) => {
             isLoading.value = true;
            let image = document.getElementsByClassName('mas-glob-qr-code')[0].getElementsByTagName('img')[0];
            if(type == 'pdf'){
                const doc = new jspdf({compress: true});

                // Convert the image element to a data URL
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.width = image.width;
                canvas.height = image.height;
                context.drawImage(image, 0, 0);
                const dataURL = canvas.toDataURL('image/jpeg');

                // Add the image to the PDF
                doc.addImage(dataURL, 'JPEG', 55, 10, 100, 100);
    

                let text = props.name;
                let fontSize = 24;


                // Calculate the text width
                const textWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;

                // Calculate the center position
                const pageWidth = doc.internal.pageSize.getWidth();
                const centerX = (pageWidth - textWidth) / 2;

                // Add the text at the center position
                doc.setFontSize(fontSize);
                doc.setFont('helvetica', 'bold');
                doc.text(text, centerX, 120);

                doc.save(props.downloadFileName+'.pdf');

                isLoading.value = false;
            }else {
                var a = document.createElement("a");
                a.href = image.src;
                a.download = props.downloadFileName+".png";
                a.click();
                isLoading.value = false;
            }
        }

        return {
            enlargeQrCode,
            downloadQRCode,
            qrCodeRef,
            isLoading,
            showEnlargedQrCode,
            ...props,
        }
    }
}
</script>

<style lang="scss">
.mas-glob-qr-code{
    display: flex;

    .qr-code-section{
        cursor: pointer;
        div{
            display: flex;
            justify-content: center;
        }
        p.enlarge{
            margin: 0;
            font-weight: 600;
            font-style: oblique;
            text-align: center;
        }
    }

    .download-options{
        display: grid;
        grid-gap: 0;
        grid-template-rows: 50px 50px;
        margin-left: 28px;

        span {
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            i {
                font-size: 30px;
                margin-right: 7px;
            }

            i.fa-file-pdf{
                color: $mas-pdf-color;
            }
            i.fa-file-image{
                color: $mas-traditionalBlue;
            }
        }
    }
}

.mas-glob-qr-code-modal{
    .ips-modal__content{
        padding: 1rem;
    }
}
</style>
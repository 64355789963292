
import momentTimeZone from 'moment-timezone';
import store from "/src/store/index.js";
// import {urlParts} from 'src/config.js'
// import {constants} from 'src/config.js';

export const dynamicTranslation = (text, params) => {
    Object.keys(params).forEach((key) =>{
        text = text.replace('{'+key+'}', params[key]);
    })
    return text;
}

export const getSavedFileURLFromPath = (filePath) =>{
    if(filePath){
        return process.env.BASE_URL_PHP+'/'+filePath.replace(/public/, 'storage');
    }
    return null;
}

export const generateQRCodeContentURL = (type, id, baseURLType = null) =>{
    let baseURL = '';
    if(baseURLType == 'mobile'){
        baseURL = process.env.BASE_URL_MOBILE;
    }else{
        baseURL = window.location.origin;
    }

    return baseURL+'/qr/'+type+'-'+id;

}

export const  convertUtcToLocalTime = (dateTime, returnFormat= null) => {
    if (!dateTime) {
        return dateTime;
    }
    try {
        const timezone = store.getters.GET_USER.TimeZone ? store.getters.GET_USER.TimeZone : 'Europe/Stockholm';
        let dateFormat = momentTimeZone(dateTime).creationData().format;
        if (!dateFormat && !returnFormat) {
            if (dateTime.includes('am') || dateTime.includes('pm')) {
                dateFormat = 'hh:mm:ss a';
            } else {
                dateFormat = 'HH:mm:ss';
            }
        } else if(returnFormat){
            dateFormat = returnFormat;
        }
        const convertedDateTime = momentTimeZone.utc(dateTime, dateFormat).tz(timezone);
        return convertedDateTime.isValid() ? convertedDateTime.format(dateFormat) : dateTime;
    } catch (err) {
        console.log('error', err)
        return dateTime;
    }
}


export const minutesToHumanReadableTime = (minutes) => {

    if(!minutes){
        return '0min';
    }

    let hours = Math.floor(minutes / 60);
    let minutes_remaining = minutes % 60;

    if (hours > 0 && minutes_remaining > 0) {
        return `${hours}hr ${minutes_remaining}min`;
    } else if (hours > 0) {
        return `${hours}hr`;
    } else {
        return `${minutes}min`;
    }
}


<template>
    <div>
    <GlobSpinner :isLoading="isLoading" />
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true" class="mas-service-log-view">
            <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
                <!-- Approve repport section -->
                <GlobRow>
                    <template v-if="approveDetails.Id">
                        <GlobCol :medium="12" class="service-log-respoisble-persons ">
                            <p class="section-head mas-m-0">
                                {{ $t('maintenance_plan.oee.approve_service.approved_by') }}
                            </p>
                        </GlobCol>
                        <GlobCol :medium="3" class="service-log-respoisble-persons ">
                            <label>
                                {{ $t('maintenance_plan.oee.approve_service.last_updated_by') }}
                                <h4>{{ approveDetails.LastUpdatedBy }}</h4>
                            </label>
                        </GlobCol>
                        <GlobCol :medium="6" class="service-log-respoisble-persons ">
                            <label>
                                    {{ $t('maintenance_plan.oee.approve_service.last_updated_at') }}
                                    <h4>{{ approveDetails.LastUpdatedAt }}</h4>
                            </label>
                        </GlobCol>
                    </template>
                    <template v-if="approveDetails.Status != 'approved'">
                        <GlobCol :medium="7">
                            <label>{{ $t('maintenance_plan.oee.approve_service.describe_action_taken') }}*</label>
                            <InputDynamic
                                :fullWidth="true"
                                styling="width: 100%; padding: .5rem;"
                                :type="'textarea'"
                                :rows="7"
                                v-model="approveDetails.Description"
                            />
                        </GlobCol>
                        <GlobCol :medium="5" v-if="!isLoading">
                            <label>
                                    <InputToggle :label="$t('maintenance_plan.oee.approve_service.troubleshooting_guide_created')" 
                                            v-model="approveDetails.TroubleshootingGuideCreated" />
                                </label>
                        </GlobCol>
                        <GlobCol :medium="12" class="mas-mb-4">
                            <button class="button"  @click="showConfimApproveModal(true)">{{ $t('maintenance_plan.oee.approve_service.approve_report') }}</button>
                            <button class="button button--orange"  @click="showPendingApproveModal(true)">{{ $t('maintenance_plan.oee.approve_service.leave_pending') }}</button>
                        </GlobCol>
                    </template>
                     <template v-else>
                         <GlobCol :medium="12" class="service-log-respoisble-persons">
                            <label>
                                {{ $t('maintenance_plan.oee.approve_service.describe_action_taken') }}
                            <div class="description">
                                <h4>{{ approveDetails.Description }}</h4>
                            </div>
                            </label>
                        </GlobCol>
                        <GlobCol :medium="12" class="service-log-respoisble-persons">
                                <label>
                                    {{ $t('maintenance_plan.oee.approve_service.troubleshooting_guide_created') }}
                                <h4>
                                    {{ approveDetails.TroubleshootingGuideCreated ? $t('general.yes') : $t('general.no') }}
                                </h4>
                                </label>
                            </GlobCol>
                    </template>
                </GlobRow>
                <hr>
                <!-- Show reported details -->
                <GlobRow >
                    <GlobCol :medium="12" class="service-log-respoisble-persons ">
                            <p class="section-head mas-m-0">
                                {{ $t('maintenance_plan.oee.reported_by') }}
                            </p>
                        </GlobCol>
                    <GlobCol :medium="4">
                        <label>
                            {{$t('maintenance_plan.oee.fields.asset')}}
                            <h4>{{oeeTimeLog.AssetName}}</h4>
                        </label>
                    </GlobCol>
                </GlobRow>
                <GlobRow  class="mas-mt-4">
                    <GlobCol :medium="4">
                        <label>
                            {{$t('maintenance_plan.oee.fields.started')}}
                            <h4>{{oeeTimeLog.StartDateTime ? convertUtcToLocalTime(oeeTimeLog.StartDateTime, 'YYYY-MM-DD HH:mm') : ''}}</h4>
                        </label>
                    </GlobCol>
                    <GlobCol :medium="4">
                        <label>
                            {{$t('maintenance_plan.oee.fields.stopped')}}
                            <h4>{{oeeTimeLog.StopDateTime ? convertUtcToLocalTime(oeeTimeLog.StopDateTime, 'YYYY-MM-DD HH:mm') : ''}}</h4>
                        </label>
                    </GlobCol>
                    <GlobCol :medium="4">
                        <label>
                            {{$t('maintenance_plan.oee.fields.run_time')}}
                            <h4>{{oeeTimeLog.RunTime}} {{$t('maintenance_plan.oee.minutes')}}</h4>
                        </label>
                    </GlobCol>
                </GlobRow>
                <GlobRow class="mas-mt-4">
                    <GlobCol :medium="4">
                        <label>
                            {{ $t('maintenance_plan.oee.fields.time_stopped') }}
                            <h4>{{ oeeTimeLog.TimeStopped ? oeeTimeLog.TimeStopped : 0 }}  {{ $t('maintenance_plan.oee.minutes') }}</h4>
                        </label>
                    </GlobCol>
                    <GlobCol :medium="4">
                        <label>
                            {{$t('maintenance_plan.oee.fields.reason')}}
                            <h4>{{oeeTimeLog.Reason}}</h4>
                        </label>
                    </GlobCol>
                    <GlobCol :medium="4">
                        <label>
                            {{$t('maintenance_plan.oee.fields.reported_by')}}
                            <h4>{{oeeTimeLog.ReportedBy}}</h4>
                        </label>
                    </GlobCol>
                    <GlobCol :medium="12" v-if="oeeTimeLog.Description">
                        <label>
                            {{$t('maintenance_plan.oee.fields.description_or_comments')}}
                            <h4>{{oeeTimeLog.Description}}</h4>
                        </label>
                    </GlobCol>
                    <GlobCol :medium="12">
                        <label>
                            {{ $t('maintenance_plan.oee.fields.solution') }}
                            <h4>{{ oeeTimeLog.Solution }}</h4>
                        </label>
    				</GlobCol>
                </GlobRow>
            </GlobXpanel>
        </GlobXpanel>
    </div>

    	<GlobConsequence :show="showApproveMaintenanceModal" 
    		@close="showConfimApproveModal(false)"  
            @confirm="updateApproval('approved')"
    		:title="$t('maintenance_plan.oee.approve_service.approve_report')" 
    		:message="$t('maintenance_plan.oee.approve_service.approve_report_confirm')"  />

        <GlobConsequence :show="showPendingMaintenanceModal" 
        		@close="showPendingApproveModal(false)"  
                @confirm="updateApproval('pending')"
        		:title="$t('maintenance_plan.oee.approve_service.leave_pending')" 
        		:message="$t('maintenance_plan.oee.approve_service.leave_pending_confirm')"  />
</template>
<script>
import { onMounted, reactive, ref} from "vue";
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import { useNotifications } from "@/plugins/notifications";
import { convertUtcToLocalTime } from '@/helpers.js';

export default {
    components: {},
    props: ['oeeTimeLogId'],
    setup(props, { emit }) {
        const { tm } = useI18n();
        const api = useApi();
        const notifications = useNotifications();
        const endpoint = api.oee;

        let approveDetails = reactive({
            'Id': '',
            'Description': '',
            'TroubleshootingGuideCreated': ''
        });

        const oeeTimeLog = ref({});

        const showApproveMaintenanceModal = ref(false);
        const showPendingMaintenanceModal = ref(false);

        const isLoading = ref(false)
        onMounted(() => {
            getData();
        })

        const getData = () => {
            isLoading.value = true;
            endpoint.getApprovalDetails(props.oeeTimeLogId).then((res) => {
                if (res.approval_details) {
                    approveDetails = Object.assign(approveDetails, res.approval_details);
                }
                oeeTimeLog.value = res.details;
                isLoading.value = false;
            }); 
        }

        const updateApproval = (status) => {
            showPendingApproveModal(false)
            showConfimApproveModal(false)
            isLoading.value = true;
            endpoint.updateApprovalStatus(props.oeeTimeLogId, {
                ...approveDetails,
                Status: status,
            }).then((res) => {
                notifications.push(
                    {
                        msg: tm('maintenance_plan')['service_log_menu']['approve_service']['reported_successfully']
                    },
                    "success",
                    false
                );

                emit('close');
            });
        }

        const showConfimApproveModal = (value) => {
            showApproveMaintenanceModal.value = value
            emit('hideApproveModal', value)
        }

        const showPendingApproveModal = (value) => {
            showPendingMaintenanceModal.value = value
            emit('hideApproveModal', value)
        }


        return {
            isLoading,
            approveDetails,
            moment,
            showApproveMaintenanceModal,
            showConfimApproveModal,
            showPendingApproveModal,
            showPendingMaintenanceModal,
            updateApproval,
            oeeTimeLog,
            convertUtcToLocalTime
        }
    }
}
</script>

<style lang="scss">

</style>
<template>
    <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true">
        <div class="crud-expand">
            <p>{{$t('administration.asset.data.add_data')}}</p>
            <span ><i class="fas fa-plus-circle" @click="showAddorEditModal = true"></i></span>
        </div>
        <GlobTable
            class="mas-mt-4"
            :maxHeight="50"
            :data="{
                head : head,
                body : body
            }"
            :actions="actions"
        />
    </GlobXpanel>

    <!-- Add or edit modal start -->
    <GlobModal :widthInPx="500" :title="$t('administration.asset.data.add')" 
        :show="showAddorEditModal && !showDeleteConfirmModal" 
        :isCRUD="true" 
        @close="closeForm()"
        v-if="showAddorEditModal">
       
        <GlobXpanel :noShadow="true" :tools="false" :avoidHR="true" :isCRUD="true">
            <GlobSpinner :isLoading="isLoadingAddorEditModal" />
            <GlobRow>
                <GlobCol :medium="12">
                    <label>
                        {{$t('administration.asset.data.name')}}*
                        <InputDynamic :fullWidth="true" :type="'text'"
                            :placeholder="$t('administration.asset.data.name')" v-model="form.Name" />
                    </label>
                </GlobCol>
                <GlobCol :medium="12">
                    <label>
                        {{$t('administration.asset.data.unit')}}*
                        <InputDynamic :fullWidth="true" :type="'text'"
                            :placeholder="$t('administration.asset.data.unit')" v-model="form.Unit" />
                    </label>
                </GlobCol>
                <GlobCol :medium="12">
                    <label>
                        {{$t('administration.asset.data.data_type')}}*
                        <InputDynamic 
                            :fullWidth="true" 
                            :type="'virtualselect'"
                            :data="dataTypes"
                            :placeholder="$t('administration.asset.data.data_type')" v-model="form.DataType" />
                    </label>
                </GlobCol>
                <GlobCol :medium="12">
                    <label>
                        {{$t('administration.asset.data.description')}}
                        <InputDynamic 
                            :fullWidth="true" 
                            :type="'textarea'"
                            :placeholder="$t('administration.asset.data.description')" 
                            :rows="4"
                            v-model="form.Description" />
                    </label>
                </GlobCol>

                <GlobCol :medium="12">
                    <button 
                    :disabled="isSaveButtonDisabled"
                    @click="save()" class="button mas-mt-3">
                    {{$t('general.button.save')}}
                    </button>
                    <button @click="closeForm()" class="button button--orange">
                    {{$t('general.button.cancel')}}
                    </button>
                    <button class="button button--red" 
                    @click="showDeleteConfirmModal = true;"
                    v-if="form.Id">
                    {{$t('general.button.delete')}}
                    </button>
                </GlobCol>
            </GlobRow>
        </GlobXpanel>
    </GlobModal>
    `<!-- Add or edit modal end -->`

    <!-- Delete confirm modal start -->
	<GlobConsequence :show="showDeleteConfirmModal" 
		@close="showDeleteConfirmModal=null"  
		@confirm = "deleteItem()"
		:title="$t('administration.asset.data.delete')"
		:message="$t('administration.asset.data.delete_confirm')"  />

    <!-- Delete confirm modal end -->
</template>

<script setup>

import { ref, computed } from 'vue';
import { useApi } from "@/plugins/api";
import { useI18n } from 'vue-i18n';
import { useNotifications } from "@/plugins/notifications";

const props = defineProps({
    data: {
        type: Array,
        required: true
    },
    assetId: {
        type: [String, Number],
        required: true
    }
});

const dataTypes = ref([
    { value: 'string', name: 'String' },
    { value: 'float', name: 'Float' },
    { value: 'integer', name: 'Integer' },
]);

const formFields = {
    Id: 0,
    Name: '',
    Unit: '',
    DataType: '',
    Description: ''
}

const form = ref(formFields);

const closeForm = () => {
    Object.assign(form.value, formFields);
    showAddorEditModal.value = false;
}

const api = useApi();
const endpoint = computed(() => api.assets);
const notifications = useNotifications();
const { tm } = useI18n();

const showAddorEditModal = ref(false);
const isLoadingAddorEditModal = ref(false);
const showDeleteConfirmModal = ref(false);



const head = computed(() => {
    return [
        {
            Alias: 'Name',
            Value: tm('administration')['asset']['data']['name']
        },
        {
            Alias: 'DataType',
            Value: tm('administration')['asset']['data']['type']
        },
        {
            Alias: 'Unit',
            Value: tm('administration')['asset']['data']['unit']
        },
        {
            Alias: 'LastValueAt',
            Value: tm('administration')['asset']['data']['last_value_at']
        },
    ]
})

const body = ref(props.data);

const actions = computed(() => { return [
    {
        name: tm('general')['button']['edit'],
        color: "green",
            action: (obj) => {
                form.value = obj;
                showAddorEditModal.value = true;
            }
    },
]});

const isSaveButtonDisabled = computed(() => {
    return !form.value.Name || !form.value.Unit || !form.value.DataType;
})


const save = async () => {
    isLoadingAddorEditModal.value = true;
    endpoint.value.saveAssetData(props.assetId, form.value).then((res) => {
        if(res.success){
            notifications.push(
                {
                    msg: tm('administration')['asset']['data']['save_success']
                },
                "success",
                false
                );
            closeForm();
            const updatedItem = res.sensor;
            const index = body.value.findIndex(item => item.Id == updatedItem.Id);
            
            
            if(index > -1){
                body.value[index] = updatedItem;
            } else{
                body.value.push(updatedItem);
            }

        }else{
             notifications.push(
                {
                    msg: tm('general')['something_went_wrong']
                },
                "warning",
                false
                );
        }
        isLoadingAddorEditModal.value = false;
    });
}

const deleteItem = async () => {
    isLoadingAddorEditModal.value = true;
    showDeleteConfirmModal.value = false;
    endpoint.value.deleteAssetData(props.assetId, form.value.Id).then((res) => {
        if(res.success){
            notifications.push(
                {
                    msg: tm('administration')['asset']['data']['delete_success']
                },
                "success",
                false
                );
            body.value = body.value.filter(item => item.Id != form.value.Id);
            closeForm();
        }else{
             notifications.push(
                {
                    msg: tm('general')['something_went_wrong']
                },
                "warning",
                false
                );
        }
        isLoadingAddorEditModal.value = false;
    });
}

</script>